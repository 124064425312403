import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import Img from "gatsby-image"
import shape1 from '../../assets/images/shape/vector-shape1.png'
import shape2 from '../../assets/images/shape/vector-shape2.png'

const OurBlog = () => {

    const { allMarkdownRemark: { blogNodes } } = useStaticQuery(graphql`
    query Last3BlogPosts($formatString: String = "MMM D, Y", $locale: String = "de") {
        allMarkdownRemark(limit: 3, sort: {fields: frontmatter___date, order: DESC}) {
          blogNodes: nodes {
            frontmatter {
              title
              description
              date(formatString: $formatString, locale: $locale)
              author
              banner {
                childImageSharp {
                  fluid(maxWidth: 1024) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            timeToRead
            fields {
              slug
            }
          }
        }
      } 
`
    )
    const blogPreview = blogNodes.map((b, i) =>
        (
            <div className="col-lg-4 col-md-6" key={i}>
                <div className="single-blog-post bg-fffbf5">

                    {
                        b.frontmatter.banner &&
                        <div className="post-image">
                            <Link to={"blog" + b.fields.slug}>
                                <Img fluid={b.frontmatter.banner?.childImageSharp.fluid} alt="Thumbnail" />
                            </Link>
                        </div>
                    }

                    <div className="post-content">
                        <ul className="post-meta d-flex justify-content-between align-items-center">
                            <li>
                                <div className="post-author d-flex align-items-center">
                                    <span>{b.frontmatter.author}</span>
                                </div>
                            </li>
                            <li>
                                <i className='flaticon-stopwatch'></i> {b.timeToRead} mins
                            </li>
                            <li>
                                <i className='flaticon-calendar'></i> {b.frontmatter.date}
                            </li>
                        </ul>
                        <h3>
                            <Link to={"blog" + b.fields.slug}>
                                {b.frontmatter.title}
                            </Link>
                        </h3>
                    </div>
                </div>
            </div>
        )
    )



    return (
        <section className="blog-area pt-100 pb-70">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">soda4LCA Blog</span>
                    <h2>News, Announcements &amp; Insights</h2>
                    <p>Take a look at our blog where we post the latest updates, releases and behind the scenes for soda4LCA </p>
                </div>

                <div className="row">
                    {blogPreview}
                </div>
            </div>

            <div className="vector-shape1">
                <img src={shape1} alt="partner" />
            </div>
            <div className="vector-shape2">
                <img src={shape2} alt="partner" />
            </div>
        </section>
    )
}

export default OurBlog