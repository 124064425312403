import React from 'react'
import partner1 from '../../assets/images/partner/ecoinvent.png'
import partner2 from '../../assets/images/partner/eco-platform-logo.svg'
import partner3 from '../../assets/images/partner/ivl-logo.svg'
import partner4 from '../../assets/images/partner/logo-iris-grand.png'
import partner5 from '../../assets/images/partner/Logo-JRC.png'
import partner6 from '../../assets/images/partner/sirim.png'

const Partner = () => {
    return (
        <div className="partner-area ptb-70 bg-fafafb">
            <div className="container">
                <div className="row align-items-center" style={{filter : 'grayscale(100%)'}}>
                    <div className="col-lg-2 col-6 col-sm-4">
                        <div className="single-partner-item">
                            <img src={partner1} alt="partner" />
                        </div>
                    </div>

                    <div className="col-lg-2 col-6 col-sm-4">
                        <div className="single-partner-item">
                            <img src={partner2} alt="partner" />
                        </div>
                    </div>

                    <div className="col-lg-2 col-6 col-sm-4">
                        <div className="single-partner-item">
                            <img src={partner3} alt="partner" />
                        </div>
                    </div>

                    <div className="col-lg-2 col-6 col-sm-4">
                        <div className="single-partner-item">
                            <img src={partner4} alt="partner" />
                        </div>
                    </div>

                    <div className="col-lg-2 col-6 col-sm-4">
                        <div className="single-partner-item">
                            <img src={partner5} alt="partner" />
                        </div>
                    </div>

                    <div className="col-lg-2 col-6 col-sm-4">
                        <div className="single-partner-item">
                            <img src={partner6} alt="partner" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Partner