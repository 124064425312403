import React from 'react'
import ReactWOW from 'react-wow'
import { Link } from 'gatsby'
import bannerImg2 from '../../../undrawio/undraw_Reviewed_docs_re_9lmr.svg'

const Banner = () => {
    return (
        <div className="seo-agency-banner">
                <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-lg-5 col-md-12">
                        <div className="banner-wrapper-content">
                            <span className="sub-title">ok*worx</span>
                            <ReactWOW delay='.1s' animation='fadeInLeft'>
                                <h1>soda4LCA</h1>
                            </ReactWOW>

                            <ReactWOW delay='.1s' animation='fadeInLeft'>
                                <p>We are absolutely committed to provide state of the art software solutions to the international LCA community, in order to help make the world a more sustainable one.</p>
                            </ReactWOW>

                            <ReactWOW delay='.1s' animation='fadeInRight'>
                                <div className="btn-box">
                                    <a href="https://bitbucket.org/okusche/soda4lca/" className="default-btn">
                                        <i className="flaticon-right"></i> 
                                        About Us 
                                        <span></span>
                                    </a>
                                </div>
                            </ReactWOW>
                        </div>
                    </div>

                    <div className="col-lg-7 col-md-12">
                        <ReactWOW delay='.1s' animation='fadeInUp'>
                            <div className="banner-wrapper-image">
                                <img src={bannerImg2} alt="banner" />
                            </div>
                        </ReactWOW>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Banner