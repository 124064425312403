import React from 'react'
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import Layout from "../components/App/Layout"
import Banner from '../components/App/Banner'
import FeaturedServices from '../components/App/FeaturedServices'
// import AboutArea from '../components/App/AboutArea'
import FunFacts from '../components/App/FunFacts'
import OurServices from '../components/App/OurServices'
// import CaseStudy from '../components/App/CaseStudy'
// import WhyChooseUs from '../components/App/WhyChooseUs'
// import Testimonials from '../components/App/Testimonials'
// import Pricing from '../components/App/Pricing'
// import AnalysisForm from '../components/App/AnalysisForm'
import Partner from '../components/App/Partner'
import OurBlog from '../components/App/OurBlog'
// import TeamMembers from '../components/App/TeamMembers'

const LandingPage = () => {
    return (
        <Layout>
            <Navbar />
            <Banner />
            <FeaturedServices />
            {/* <AboutArea /> */}
            <FunFacts />
            <OurServices />
            {/* <CaseStudy /> */}
            {/* <WhyChooseUs /> */}
            {/* <Testimonials /> */}
            {/* <Pricing /> */}
            {/* <TeamMembers /> */}
            {/* <AnalysisForm /> */}
            <Partner />
            <OurBlog />
            <Footer />
        </Layout>
    )
}

export default LandingPage
