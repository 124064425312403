import React from 'react'
import {Link} from 'gatsby'

const FeaturedServices = () => {
    return (
        <section className="featured-services-area pt-100 pb-70">
            <div className="container">
                <div className="section-title">
                    <h2>What is soda4LCA?</h2>
                    <p>soda4LCA is a web-based database application designed to store and retrieve Life Cycle Inventory (LCI) and Life Cycle Impact Assessment (LCIA) datasets formatted in the ILCD format. It also exposed a RESTful service interface to communicate directly with other LCA software tools and/or databases. Multiple soda4LCA nodes can be joined to a network, where a search operation will query all nodes in the network.</p>
                </div>

                <div className="row">
                    <div className="col-lg-4 col-sm-6">
                        <div className="single-featured-services-box">
                            <div className="icon">
                                <i className="flaticon-analysis"></i>
                            </div>
                            <h3>
                                <Link to="/service-details">
                                Reliable Consultancy
                                </Link>
                            </h3>
                            <p>With our expertise in software development, LCA data formats and data distribution, we can offer our clients tailored solutions for their individual needs.</p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6">
                        <div className="single-featured-services-box">
                            <div className="icon">
                                <i className="flaticon-structure"></i>
                            </div>
                            <h3>
                                <Link to="/service-details">
                                    Tailored Features
                                </Link>
                            </h3>
                            <p>As maintainer of the soda4LCA open source software project, we coordinate contributions from the international developer community and are actively developing the software further for our clients, providing custom features and bug fixes as well as pre-release builds and early access to new features.</p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6 offset-lg-0 offset-sm-3">
                        <div className="single-featured-services-box">
                            <div className="icon">
                                <i className="flaticon-idea"></i>
                            </div>
                            <h3>
                                <Link to="/service-details">
                                    Operate Instances
                                </Link>
                            </h3>
                            <p>We offer to operate a soda4LCA instance for you in a best-in-class green European data center through our LCA Data Hub service.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default FeaturedServices