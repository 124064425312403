import React from 'react'
import {Link} from 'gatsby'
import serviceIcon7 from '../../assets/images/services/service-icon7.png'
import serviceIcon8 from '../../assets/images/services/service-icon8.png'
import serviceIcon9 from '../../assets/images/services/service-icon9.png'
import serviceIcon10 from '../../assets/images/services/service-icon10.png'
import serviceIcon11 from '../../assets/images/services/service-icon11.png'
import serviceIcon12 from '../../assets/images/services/service-icon12.png'
import serviceShape4 from '../../assets/images/services/service-shape4.png'

const OurServices = () => {
    return (
        <section className="services-area ptb-100">
            <div className="container">
                <div className="section-title">
                    <h2>Technology Stack</h2>
                    <p>Modern web development architecture based on client-side and a web server.</p>
                </div>

                <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="single-services-box-item">
                            <div className="icon">
                                <img src={serviceIcon7} alt="services" />
                            </div>
                            <h3>
                                <Link to="/service-details">
                                    Java
                                </Link>
                            </h3>
                            <p>Class-based, object-oriented programming language that is designed to have as few implementation dependencies as possible. It is a general-purpose programming language intended to let application developers write once, run anywhere.</p>
                            {/* <Link to="/service-details" className="learn-more-btn">
                                <i className="flaticon-right"></i> 
                                Learn More
                            </Link> */}
                            <div className="shape">
                                <img src={serviceShape4} alt="services" />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="single-services-box-item">
                            <div className="icon">
                                <img src={serviceIcon8} alt="services" />
                            </div>
                            <h3>
                                <Link to="/service-details">
                                    Maven
                                </Link>
                            </h3>
                            <p>Maven is a build automation tool used primarily for Java projects. The Maven project is hosted by the Apache Software Foundation, where it was formerly part of the Jakarta Project.</p>
                            {/* <Link to="/service-details" className="learn-more-btn">
                                <i className="flaticon-right"></i> 
                                Learn More
                                
                            </Link> */}
                            <div className="shape">
                                <img src={serviceShape4} alt="services" />
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="single-services-box-item ">
                            <div className="icon">
                                <img src={serviceIcon9} alt="services" />
                            </div>
                            <h3>
                                <Link to="/service-details">
                                    Continuous Testing
                                </Link>
                            </h3>
                            <p>Process of executing automated tests as part of the software delivery pipeline to obtain immediate feedback on the business risks associated with a software release candidate.</p>
                            {/* <Link to="/service-details" className="learn-more-btn">
                                <i className="flaticon-right"></i> 
                                Learn More
                                
                            </Link> */}
                            <div className="shape">
                                <img src={serviceShape4} alt="services" />
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="single-services-box-item">
                            <div className="icon">
                                <img src={serviceIcon10} alt="services" />
                            </div>
                            <h3>
                                <Link to="/service-details">
                                    Git Revision Control
                                </Link>
                            </h3>
                            <p>Distributed version-control system for tracking changes in any set of files, Its goals include speed, data integrity, and support for distributed, non-linear workflows.</p>
                            {/* <Link to="/service-details" className="learn-more-btn">
                                <i className="flaticon-right"></i> 
                                Learn More
                            </Link> */}
                            <div className="shape">
                                <img src={serviceShape4} alt="services" />
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="single-services-box-item">
                            <div className="icon">
                                <img src={serviceIcon11} alt="services" />
                            </div>
                            <h3>
                                <Link to="/service-details">
                                    XML
                                </Link>
                            </h3>
                            <p>Markup language that defines a set of rules for encoding documents in a format that is both human-readable and machine-readable. The World Wide Web Consortium's XML 1.0 Specification of 1998 and several other related specifications—all of them free open standards</p>
                            {/* <Link to="/service-details" className="learn-more-btn">
                                <i className="flaticon-right"></i> 
                                Learn More
                            </Link> */}
                            <div className="shape">
                                <img src={serviceShape4} alt="services" />
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="single-services-box-item">
                            <div className="icon">
                                <img src={serviceIcon12} alt="services" />
                            </div>
                            <h3>
                                <Link to="/service-details">
                                    Open Source Project
                                </Link>
                            </h3>
                            <p>Source code is made freely available for possible modification and redistribution. Include permission to use the source code, design documents, or content of the product.</p>
                            {/* <Link to="/service-details" className="learn-more-btn">
                                <i className="flaticon-right"></i> 
                                Learn More
                            </Link> */}
                            <div className="shape">
                                <img src={serviceShape4} alt="services" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default OurServices